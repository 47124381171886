.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 32px;
}

th {
  background-color: rgb(117, 201, 250);
}

td {
  background-color: rgb(205, 235, 253);
}

form {
  display: flex;
  gap: 5px;
}

form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

form * {
  font-size: 28px;
}